import React, { useState } from 'react'

import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import grey from '@material-ui/core/colors/grey'

interface IProps {
  age: number,
  title: string,
  username: string,
}

const useStyles = makeStyles(theme => ({
  postedBy: {
    color: grey[500],
    marginBottom: theme.spacing(1),
  },
  title: {
  }
}))

const FeedItemTitle: React.FC<IProps> = (props) => {

  const classes = useStyles()

  return (
    <Box className={classes.title}>
      <Typography className={classes.postedBy} variant="body2">
        Posted by {props.username} {moment(props.age).fromNow()}
      </Typography>
      <Typography variant="h6">
        {props.title}
      </Typography>
    </Box>
  )
}

export default FeedItemTitle