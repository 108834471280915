import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'

import Box from '@material-ui/core/Box'

import FeedItem from './FeedItem'

import CreatePost from '../createPost'

import { IPost } from 'api/rumble'

import moment from 'moment'


interface IProps {
  posts: IPost[],

  onSubmitVote: (postId: number, amount: number) => void,
}
const useStyles = makeStyles(theme => ({
  feed: {
    marginBottom: theme.spacing(2),
  },
}))

const Feed: React.FC<IProps> = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.feed}>
      <CreatePost />
      {props.posts.map((post, i) => (
        <FeedItem
          key={i}
          post={post}
          onSubmitVote={(amount: number) => props.onSubmitVote(post.postId, amount)}
        />
      ))}
    </Box>
  )
}

export default Feed