import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'

import parse from 'url-parse'

interface IProps {
  url: string,
}

const useStyles = makeStyles(theme => ({
  url: {
    color: blue[500],
    fontSize: 14,
    textDecoration: 'none',
    '&:hover': {
      color: blue[300],
    },
  }
}))

const FeedItemUrl: React.FC<IProps> = (props) => {

  const classes = useStyles()
  const { hostname } = parse(props.url)

  return <a className={classes.url} href={props.url}>{hostname}</a>
}

export default FeedItemUrl