import { combineReducers } from '@reduxjs/toolkit'

import authReducer from 'features/authentication/authSlice'
import walletReducer from 'features/wallet/walletSlice'

const rootReducer = combineReducers({
  auth: authReducer,
  wallet: walletReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer