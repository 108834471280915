import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { IPost } from 'api/rumble'

import Feed from 'features/feed/Feed'


interface IProps {
  posts: IPost[],
}

const useStyles = makeStyles(theme => ({
  tabPanelContainer: {
    marginTop: theme.spacing(4),
  }
}))

const PostHistory: React.FC<IProps> = (props) => {

  const classes = useStyles()

  const [tabValue, setTabValue] = React.useState(0)

  const handleChange = (e: any, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <>
      <Tabs
        centered
        indicatorColor="primary"
        value={tabValue}
        onChange={handleChange}
      >
        <Tab label="Posts" />
        <Tab label="Votes" />
      </Tabs>
      <Box className={classes.tabPanelContainer}>
        {tabValue === 0 && (
          <Feed
            posts={props.posts}
            onSubmitVote={() => {}}
          />
        )}
        {tabValue === 1 && (
          <div>votes</div>
        )}
      </Box>
    </>
  )
}

export default PostHistory