import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from 'app/store'

import {
  getCredits,
  placeVote,
} from 'api/rumble'

interface WalletState {
  credits: number,
}
const initialState: WalletState = {
  credits: 0,
}

const wallet = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    receiveCredits(state, action: PayloadAction<number>) {
      state.credits = action.payload
    },
  },
})

export const { receiveCredits } = wallet.actions

export default wallet.reducer

export const fetchCredits = (): AppThunk => async (dispatch, getState) => {
  try {
    const { jwt } = getState().auth
    const credits = await getCredits(jwt)
    dispatch(receiveCredits(credits))
  } catch (err) {
    return 0
  }
}