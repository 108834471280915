import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'

import CreditBar from './CreditBar'

import { fetchCredits } from '../wallet/walletSlice'

import SignIn from '../signIn'

import {
  addCredits,
  getCredits,
} from 'api/rumble'

const CreditBarContainer: React.FC = () => {
  const dispatch = useDispatch()

  const { credits } = useSelector((state: RootState) => state.wallet)
  const { authenticated, jwt } = useSelector((state: RootState) => state.auth)
  useEffect(() => {
    dispatch(fetchCredits())
  }, [authenticated])

  const handleAddPress = async () => {
    await addCredits(1000, jwt)
    dispatch(fetchCredits())
  }

    /*
  const [isSignInOpen, setIsSignInOpen] = useState(false)
  const [metadata, setMetadata] = useState<IMetadata | undefined>()

  const isModalOpen = !!metadata

  const { authenticated, jwt } = useSelector((state: RootState) => state.auth)
  const handleSubmitPostBar = async (url: string) => {
    if (!authenticated) {
      setIsSignInOpen(true)
    } else {
      const res = await fetchMetadata(url)
      setMetadata(res)
    }
  }

  const handleCreatePost = async (title: string, url: string) => {
    const post = await createPost(title, url, jwt)
    window.location.reload()
  }
  */

  return (
    <CreditBar
      balance={credits}
      onAddPress={handleAddPress}
    />
  )
}

export default CreditBarContainer