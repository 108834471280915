import React, { useState } from 'react'

import CreatePostBar from './CreatePostBar'
import CreatePostModal from './CreatePostModal'

import { IMetadata } from 'api/rumble'

interface IProps {
  isModalOpen: boolean,

  metadata?: IMetadata,

  dismissModal: () => void,
  onSubmitPost: (title: string, url: string) => void,
  onSubmitPostBar: (url: string) => void,
}

const CreatePost: React.FC<IProps> = (props) => {

  return (
    <>
      <CreatePostBar onSubmit={props.onSubmitPostBar} />
      {props.isModalOpen && (
        <CreatePostModal
          dismiss={props.dismissModal}
          metadata={props.metadata!}
          onSubmit={props.onSubmitPost}
        />
      )}
    </>
  )
}

export default CreatePost