import React, { useState } from 'react'

import SideBar from './SideBar'

const SideBarContainer: React.FC = (props) => {
  return (
    <SideBar />
  )
}

export default SideBarContainer