import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import grey from '@material-ui/core/colors/grey'

interface IProps {
  contain?: boolean,
  height?: number,
  source?: string,
  width?: number,
}

const useStyles = makeStyles(theme => ({
  image: {
  },
  thumbnail: {
    backgroundColor: grey[100],
  }
}))

const FeedItemThumbnail: React.FC<IProps> = (props) => {

  const classes = useStyles()

  return (
    <Box
      className={classes.thumbnail}
      style={{
        backgroundImage: `url(${props.source})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: props.contain ? 'contain' : 'cover',
        height: props.height,
        width: props.width,
      }}
    />
  )
}

export default FeedItemThumbnail