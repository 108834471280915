import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'

import CreatePost from './CreatePost'

import SignIn from '../signIn'

import {
  createPost,
  fetchMetadata,
  IMetadata,
} from 'api/rumble'

const CreatePostContainer: React.FC = () => {

  const [isSignInOpen, setIsSignInOpen] = useState(false)
  const [metadata, setMetadata] = useState<IMetadata | undefined>()

  const isModalOpen = !!metadata

  const { authenticated, jwt } = useSelector((state: RootState) => state.auth)
  const handleSubmitPostBar = async (url: string) => {
    if (!authenticated) {
      setIsSignInOpen(true)
    } else {
      const res = await fetchMetadata(url)
      console.log(res)
      setMetadata(res)
    }
  }

  const handleCreatePost = async (title: string, url: string) => {
    const post = await createPost(title, url, jwt)
    window.location.reload()
  }

  return (
    <>
      <CreatePost
        dismissModal={() => setMetadata(undefined)}
        isModalOpen={isModalOpen}
        metadata={metadata}
        onSubmitPostBar={handleSubmitPostBar}
        onSubmitPost={handleCreatePost}
      />
      {isSignInOpen && <SignIn dismiss={() => setIsSignInOpen(false)} />}
    </>
  )
}

export default CreatePostContainer