import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from 'app/store'

import {
  AuthSuccess,
  IUser,
  getJwt,
  getUserInfo,
} from 'api/rumble'

interface AuthState {
  authenticated: boolean,
  jwt: string,
  userInfo: IUser,
}
export const initialState: AuthState = {
  authenticated: false,
  jwt: '',
  userInfo: {
    email: '',
    phoneNumber: '',
    username: '',
    userId: -1,
  }
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    receiveAuthSuccess(state, action: PayloadAction<AuthSuccess>) {
      state.authenticated = true
      state.jwt = action.payload.jwt
      state.userInfo = action.payload.userInfo
    },
    receiveUserInfo(state, action: PayloadAction<IUser>) {
      state.userInfo = action.payload
    },
    signOut(state, action: PayloadAction<any>) {
      state.authenticated = initialState.authenticated
      state.jwt = initialState.jwt
      state.userInfo = initialState.userInfo
    }
  },
})

export const {
  receiveAuthSuccess, 
  receiveUserInfo,
  signOut,
} = auth.actions

export default auth.reducer

export const signIn = (username: string, password: string): AppThunk => async (dispatch, getState) => {
  try {
    const jwt = await getJwt(username, password)
    console.log(jwt)
    const userInfo = await getUserInfo(jwt)
    dispatch(receiveAuthSuccess({ jwt, userInfo }))
  } catch (err) {
    console.log(err)
  }
}

export const fetchUserInfo = (): AppThunk => async (dispatch, getState) => {
  try {
    const { jwt } = getState().auth
    const userInfo = await getUserInfo(jwt)
    dispatch(receiveUserInfo(userInfo))
  } catch (err) {
    console.log(err)
  }
}