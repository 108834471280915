import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'

import { IPost, getPosts, getVotes } from 'api/rumble'

import PostHistory from './PostHistory'

// import { fetchPostHistory } from './historySlice'

interface IProps {
}

const HistoryContainer: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()

  const [posts, setPosts] = useState<IPost[]>([])
  
  const { jwt, userInfo } = useSelector((state: RootState) => state.auth)
  useEffect(() => {
    const fetchPostHistory = async () => {
      const posts = await getPosts({ userId: userInfo.userId })
      const postIds = posts.map(post => post.postId)
      const votes = await getVotes(postIds, jwt)
      console.log(votes)
      setPosts(posts)
    }
    fetchPostHistory()
  }, [userInfo.userId])
  // dispatch(fetchPostHistory())

  return (
    <>
      <PostHistory posts={posts} />
    </>
  )
}

export default HistoryContainer