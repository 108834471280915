import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'

import CreditBalance from './CreditBalance'

interface IProps {
  balance: number,

  onAddPress: () => void,
}

const useStyles = makeStyles(theme => ({
  balance: {
  },
}))

const CreditBar: React.FC<IProps> = (props) => {
  const classes = useStyles()
  return (
    <Paper
      className={classes.balance}
    >
      <CreditBalance
        balance={props.balance}
        onAddPress={props.onAddPress}
      />
    </Paper>
  )
}

export default CreditBar