import React from 'react'

import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom'

import HomePage from 'pages/home'
import ProfilePage from 'pages/profile'

const Router: React.FC = (props) => {
  return (
    <BrowserRouter>
      {props.children}
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/profile">
          <ProfilePage />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Router