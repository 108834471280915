import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { signIn } from 'features/authentication/authSlice'

import SignInModal from './SignInModal'

interface IProps {
  dismiss: () => void,
}

const SignInContainer: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()

  const handleSignIn = async (username: string, password: string) => {
    dispatch(signIn(username, password))
    props.dismiss()
  }

  return (
    <SignInModal
      dismiss={props.dismiss}
      onSignIn={handleSignIn}
    />
  )
}

export default SignInContainer