import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { makeStyles } from '@material-ui/core'

import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import blue from '@material-ui/core/colors/blue'

import Logo from './Logo'

import Register from '../register'
import SignIn from '../signIn'

interface IProps {
  username: string,

  onSignOut: () => void,
}

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: '#FFF',
    color: blue[700],
  },
  signInButton: {
    marginLeft: theme.spacing(2),
  },
  signOutButton: {
    color: '#FFF',
  },
  userNav: {
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  }
}))

const TopBar: React.FC<IProps> = (props) => {
  const classes = useStyles()
  let history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)
  const [isSignInOpen, setIsSignInOpen] = useState(false)

  const handleAccountButtonClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSignOut = (event: React.MouseEvent) => {
    handleClose()
    props.onSignOut()
  }

  const handleClose = () => {
    setAnchorEl(null)
    // props.onSignOut()
  }

  const handleProfileClick = () => {
    console.log('profile click')
    history.push('/profile')
    handleClose()
  }

  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar>
          <Logo />

          <Box className={classes.userNav}>
            {!!props.username ? (
                <>
                  <IconButton onClick={handleAccountButtonClick}>
                    <Avatar className={classes.avatar}>
                      {props.username.toUpperCase().charAt(0)}
                    </Avatar>
                  </IconButton>
                </>
              ) : (
                <>
                  <Button
                    className={classes.signOutButton}
                    onClick={() => setIsRegisterOpen(true)}
                  >
                    Register
                  </Button>
                  <Button
                    className={classes.signInButton}
                    onClick={() => setIsSignInOpen(true)}
                    variant="contained"
                  >
                    Sign In
                  </Button>
                </>
              )
            }
          </Box>

        </Toolbar>
      </Container>

      {isRegisterOpen && <Register dismiss={() => setIsRegisterOpen(false)} />}
      {isSignInOpen && <SignIn dismiss={() => setIsSignInOpen(false)} />}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
        <MenuItem onClick={handleSignOut}>Logout</MenuItem>
      </Menu>

    </AppBar>
  )
}

export default TopBar