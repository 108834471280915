import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import blue from '@material-ui/core/colors/blue'

import FeedItemTitle from '../FeedItemTitle'
import FeedItemThumbnail from '../FeedItemThumbnail'
import FeedItemUrl from '../FeedItemUrl'

import { IPost } from 'api/rumble'

interface IProps {
  age: number,
  thumbnail: string,
  title: string,
  url: string,
  username: string,
}

const useStyles = makeStyles(theme => ({
  linkItem: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  thumbnailContainer: {
    borderRadius: 4,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    overflow: 'hidden',
  },
  title: {

  },
  titleContainer: {
    flex: 1,
    margin: theme.spacing(2),
  },
}))

const LinkItem: React.FC<IProps> = (props) => {

  const classes = useStyles()

  return (
    <Box className={classes.linkItem}>
      <Box className={classes.titleContainer}>
        <FeedItemTitle
          age={props.age}
          title={props.title}
          username={props.username}
        />
        <FeedItemUrl url={props.url} />
      </Box>
      <Box className={classes.thumbnailContainer}>
        <FeedItemThumbnail height={200} width={256} source={props.thumbnail} />
      </Box>
    </Box>
  )
}

export default LinkItem