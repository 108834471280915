import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'

import Balance from './Balance'

import CreditBar from 'features/creditBar'

interface IProps {
}

const useStyles = makeStyles(theme => ({
  sideBar: {
    height: 300,
    padding: theme.spacing(1),
  },
}))

const SideBar: React.FC<IProps> = (props) => {

  const classes = useStyles()

  return (
    <>
      <CreditBar />
    </>
  )
}

export default SideBar