import React from 'react'

import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import Feed from 'features/feed'
import SideBar from 'features/sideBar'

const useStyles = makeStyles(theme => ({
  feedContainer: {
    flex: 2,
  },
  homePage: {
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  sideBar: {
    height: 350,
  },
  sideBarContainer: {
    flex: 1,
    marginLeft: theme.spacing(4),
  },
}))

const HomePage: React.FC = (props) => {
  const classes = useStyles()
  return (
    <Container className={classes.homePage}>
      <Box className={classes.feedContainer}>
        <Feed />
      </Box>
      <Box className={classes.sideBarContainer}>
        <SideBar />
      </Box>
    </Container>
  )
}

export default HomePage