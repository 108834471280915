import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import blueGrey from '@material-ui/core/colors/blueGrey'
import blue from '@material-ui/core/colors/blue'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import purple from '@material-ui/core/colors/purple'
import red from '@material-ui/core/colors/red'
import yellow from '@material-ui/core/colors/yellow'

import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

import numeral from 'numeral'


interface IProps {
  long: number,
  score: number,
  short: number,

  onSubmit: (amount: number) => void,
}

const useStyles = makeStyles(theme => ({
  addButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  amount: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 18,
    height: 32,
    justifyContent: 'center',
    width: 32,
  },
  bottomBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  total: {
  },
  totalInput: {
    textAlign: 'center',
  },
  popover: {
    padding: theme.spacing(1),
  },
  ratioBar: {
    display: 'flex',
    backgroundColor: grey[200],
    height: 3,
  },
  ratioBarFillPositive: {
    backgroundColor: green[400],
    height: 3,
  },
  ratioBarFillNegative: {
    backgroundColor: red[400],
    flex: 1,
    height: 3,
  },
  score: {
  },
  vote: {
    alignItems: 'center',
    display: 'flex',
  },
  votes: {
    display: 'flex',
  },
  voteSpacer: {
    width: theme.spacing(1),
  },
  votesContainer: {
    marginRight: theme.spacing(2),
  }
}))

const Score: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const [customValue, setCustomValue] = React.useState(0)
  const [prefix, setPrefix] = React.useState('+')

  const handleUpClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
    setPrefix('+')
    setCustomValue(0)
  }
  const handleDownClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
    setPrefix('-')
    setCustomValue(0)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomValue(parseInt(e.target.value))
  }

  const handleSubmit = (e: React.FormEvent) => {
    props.onSubmit(prefix === '-' ? -1*customValue : customValue)
    handleClose()
    e.preventDefault()
  }

  const add = (val: number) => {
    setCustomValue((customValue || 0) + val)
  }

  const { long, short } = props

  const formattedLong = numeral(long).format('0a')
  const formattedShort = numeral(short).format('0a')

  let ratio = 0
  if (short) {
    ratio = long / (long + short)
  } else if (long) {
    ratio = 1
  }

  return (
    <Toolbar className={classes.score}>
      <div className={classes.votesContainer}>
        <div style={{ display: 'flex' }}>
          <Box className={classes.vote}>
            <IconButton onClick={handleUpClick}>
              <ThumbUpIcon />
            </IconButton>
            <Typography variant="body2">{formattedLong}</Typography>
          </Box>
          <div className={classes.voteSpacer} />
          <Box className={classes.vote}>
            <IconButton onClick={handleDownClick}>
              <ThumbDownIcon />
            </IconButton>
            <Typography variant="body2">{formattedShort}</Typography>
          </Box>
        </div>

        <div className={classes.ratioBar}>
          {(!!long || !!short) && (
            <>
              <div
                className={classes.ratioBarFillPositive}
                style={{
                  width: `${ratio * 100}%`,
                }}
              />
              <div className={classes.ratioBarFillNegative} />
            </>
          )}
        </div>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <form onSubmit={handleSubmit} className={classes.popover}>
          <Box className={classes.total}>
            <TextField
              className={classes.totalInput}
              onChange={handleChange}
              placeholder="0"
              fullWidth
              label="Total"
              variant="filled"
              value={`${prefix}${customValue}`}
            />
          </Box>
          <Box className={classes.addButtons}>
            <IconButton onClick={() => add(1)}>
              <div className={classes.amount}>{prefix}1</div>
            </IconButton>
            <IconButton onClick={() => add(10)}>
              <div className={classes.amount}>{prefix}10</div>
            </IconButton>
            <IconButton onClick={() => add(100)}>
              <div className={classes.amount}>{prefix}100</div>
            </IconButton>
            <IconButton onClick={() => add(1000)}>
              <div className={classes.amount}>{prefix}1k</div>
            </IconButton>
            <IconButton onClick={() => add(10000)}>
              <div className={classes.amount}>{prefix}10k</div>
            </IconButton>
          </Box>
          <Divider
            
          />
          <Box className={classes.bottomBar}>
            <Button className={classes.cancelButton} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Vote
            </Button>
          </Box>
        </form>
      </Popover>
    </Toolbar>
  )
}

export default Score