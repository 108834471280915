import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'

import { getPosts, IPost, placeVote } from 'api/rumble'

import { fetchCredits } from 'features/wallet/walletSlice'

import Feed from './Feed'


interface IProps {
}


const FeedContainer: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const [posts, setPosts] = useState<IPost[]>([])

  const { authenticated, jwt } = useSelector((state: RootState) => state.auth)
  const { credits } = useSelector((state: RootState) => state.wallet)

  useEffect(() => {
    async function fetchPosts () {
      const res = await getPosts()
      setPosts(res)
    }
    fetchPosts()
  }, [])

  const handleSubmitVote = async (postId: number, amount: number) => {

    if (Math.abs(amount) > credits) {
      alert('Insufficient funds.')
      return
    }

    try {
      const type = amount > 0 ? 'LONG' : 'SHORT'
      await placeVote(postId, Math.abs(amount), type, jwt)
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Feed
      posts={posts}
      onSubmitVote={handleSubmitVote}
    />
  )
}

export default FeedContainer