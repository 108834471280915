import React, { useState, FormEvent } from 'react'

import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import FeedItemThumbnail from 'features/feed/FeedItem/FeedItemThumbnail'

import { IMetadata } from 'api/rumble'

interface IProps {
  metadata: IMetadata,
  dismiss: () => void,
  onSubmit: (title: string, url: string) => void,
}

const useStyles = makeStyles(theme => ({
  content: {
    // alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  inputContainer: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
  titleInput: {
    fontSize: 24,
    lineHeight: 1.5,
  },
}))

const CreatePostModal: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')

  const handleSubmit = (event: FormEvent) => {
    props.onSubmit(title || props.metadata.title, props.metadata.url)
    event.preventDefault()
  }

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }


  const {
    contentType,
    image,
    url,
  } = props.metadata

  const thumbnailSrc = contentType.startsWith('image') ? url : image

  return (
    <Dialog open={true} onClose={props.dismiss} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <DialogTitle>Create Post</DialogTitle>
        <DialogContent className={classes.content}>
          <Box className={classes.inputContainer}>
            <TextField
              error={Boolean(titleError)}
              helperText={titleError}
              label="Title"
              onChange={handleTitleChange}
              fullWidth
              size="medium"
              multiline={true}
              value={!!title ? title : props.metadata.title}
              InputProps={{
                classes: {
                  input: classes.titleInput,
                }
              }}
            />
          </Box>
          <Box>
            <FeedItemThumbnail
              height={156}
              source={thumbnailSrc}
              width={156}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.dismiss} type="button">Cancel</Button>
          <Button variant="contained" color="primary" type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreatePostModal