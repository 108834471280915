import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import FeedItemTitle from '../FeedItemTitle'
import FeedItemThumbnail from '../FeedItemThumbnail'

interface IProps {
  age: number,
  title: string,
  thumbnail: string,
  url: string,
  username: string,
}

const useStyles = makeStyles(theme => ({
  titleContainer: {
    margin: theme.spacing(2),
  }
}))

const ImageItem: React.FC<IProps> = (props) => {

  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.titleContainer}>
        <FeedItemTitle
          age={props.age}
          title={props.title}
          username={props.username}
        />
      </Box>
      <FeedItemThumbnail
        contain={true}
        height={300}
        source={props.url}
      />
    </Box>
  )
}

export default ImageItem