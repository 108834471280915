import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'

import grey from '@material-ui/core/colors/grey'

import ImageItem from './ImageItem'
import LinkItem from './LinkItem'
import Score from './Score'

import { IPost } from 'api/rumble'

interface IProps {
  post: IPost,
  
  onSubmitVote: (amount: number) => void,
}

const useStyles = makeStyles(theme => ({
  bottomBar: {
    display: 'flex',
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    flex: 1,
  },
  thumbnail: {
    backgroundColor: grey[200],
    height: 300,
    width: 300,
  },
}))

const FeedItem: React.FC<IProps> = (props) => {

  const classes = useStyles()

  const {
    createdAt,
    contentType,
    long,
    score,
    short,
    thumbnail,
    title,
    url,
    username,
  } = props.post

  const age = createdAt * 1000

  const renderContent = () => {
    if (contentType && contentType.startsWith('image')) {
      return (
        <ImageItem
          age={age}
          title={title}
          thumbnail={thumbnail}
          url={url}
          username={username}
        />
      )
    } else {
      return (
        <LinkItem
          age={age}
          thumbnail={thumbnail}
          title={title}
          url={url}
          username={username}
        />
      )
    }
  }

  return (
    <Card className={classes.card}>
      {renderContent()}
      <Box className={classes.bottomBar}>
        <Score
          long={long}
          score={score}
          short={short}
          onSubmit={props.onSubmitVote}
        />
      </Box>
    </Card>
  )
}

export default FeedItem