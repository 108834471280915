import camelcase from 'camelcase-keys'
import snakecase from 'snakecase-keys'

const BASE_URL = 'https://rumble-backend.herokuapp.com/api/'

export interface AuthSuccess {
  jwt: string,
  userInfo: IUser,
}

export interface IPost {
  contentType: string,
  createdAt: number,
  long: number,
  postId: number,
  score: number,
  short: number,
  thumbnail: string,
  title: string,
  url: string,
  username: string,
}

export interface IUser {
  email: string,
  phoneNumber: string,
  username: string,
  userId: number,
}

export interface IMetadata {
  contentType: string,
  title: string,
  image: string,
  url: string,
}

export async function placeVote (postId: number, amount: number, type: string, jwt: string): Promise<any> {
  return await post('vote/create', {
    "post_id": postId,
    amount: amount * 1000000,
    "vote_type": type,
  }, jwt)
}

export async function createPost (title: string, url: string, jwt: string): Promise<any> {
  return await post('post/create', {
    title,
    uri: url,
  }, jwt)
}

export async function addCredits (amount: number, jwt: string): Promise<number> {
  try {
    return await post('credit/info', {
      amount: amount * 1000000,
      add: true,
    }, jwt).then(res => res.amount / 1000000)
  } catch (err) {
    return -1
  }
}

export async function getCredits (jwt: string): Promise<number> {
  try {
    return await post('credit/info', {
      amount: 0,
      add: true,
    }, jwt).then(res => res.amount / 1000000)
  } catch (err) {
    return 0
  }
}

export async function getVotes (postIds: number[], jwt: string): Promise<any> {
  try {
    return post('vote', { postIds }, jwt).then(res => camelcase(res.votes))
  } catch (err) {
    console.log(err)
  }
}

export async function getPosts (data?: any): Promise<IPost[]> {
  try {
    const posts: any[] = await post('post', data).then(res => res.posts)
    const formatted: IPost[] = posts.map(post => {
      return {
        contentType: post['content_type'] as string,
        createdAt: post['created_at'] as number,
        long: post['long_score'] / 1000000 as number,
        postId: post['post_id'] as number,
        score: post.score / 1000000 as number,
        short: post['short_score'] / 1000000 as number,
        thumbnail: post['metadata_image'],
        title: post.title as string,
        url: post.uri as string,
        username: post.username as string,
      }
    })
    return formatted
  } catch (err) {
    console.log(err)
    return []
  }
}

export async function getJwt (username: string, password: string): Promise<string> {
  return await post('user/authenticate', {
    username,
    password,
  }).then(res => {
    const jwt = res.jwtToken
    if (!jwt) {
      throw new Error('Auth failed')
    }
    return jwt
  })
}

export async function getUserInfo (jwt: string): Promise<IUser> {
  return await post('user/info', undefined, jwt).then(res => ({
    email: res.email as string,
    phoneNumber: res['phone_number'] as string,
    userId: res['user_id'] as number,
    username: res.username as string,
  }))
}

export async function createUser (username: string, email: string, password: string): Promise<any> {
  return await post('user/create', {
    email,
    password,
    username,
  })
}

export async function fetchMetadata (uri: string): Promise<IMetadata> {
  return await post('content', { uri })
    .then(res => {
      return {
        contentType: res['content_type'] || "text/html",
        image: res.image || "",
        title: res.title || "",
        url: uri,
      }
    })
}

async function post (url: string, data = {}, jwt?: string): Promise<any> {
  const headers: any = {
    'Content-Type': 'application/json',
  }
  if (jwt) {
    headers['X-JWT'] = jwt
  }
  try {
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'POST',
      headers,
      mode: 'cors',
      body: JSON.stringify(snakecase(data)),
    })
    .then(res => {
      if (!res.ok) {
        throw Error(res.statusText)
      }
      return res
    })
    .then(res => res.json())
    .then(res => camelcase(res))
    
    return response
  } catch (err) {
    throw err
  }
}