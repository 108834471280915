import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'

import TopBar from 'features/topBar'
import Router from 'router'

import { fetchUserInfo } from 'features/authentication/authSlice'
import { fetchCredits } from 'features/wallet/walletSlice'

interface IProps {
}

const App: React.FC<IProps> = (props) => {

  const dispatch = useDispatch()
  const { authenticated } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    // dispatch(fetchCredits())
    // dispatch(fetchUserInfo())
  }, [authenticated])

  return (
    <Router>
      <TopBar />
    </Router>
  )
}

export default App
