import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { signOut } from 'features/authentication/authSlice'
import { RootState } from 'app/rootReducer'

import TopBar from './TopBar'

interface IProps {
}

const TopBarContainer: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state: RootState) => state.auth)

  return (
    <TopBar
      onSignOut={() => dispatch(signOut())}
      username={userInfo.username}
    />
  )
}

export default TopBarContainer