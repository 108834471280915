import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import InsertLinkIcon from '@material-ui/icons/InsertLink'

import isUrl from 'is-url'

interface IProps {
  onSubmit: (url: string) => void,
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  inputContainer: {
    marginRight: theme.spacing(2),
  },
}))

const validatePostUrl = (url: string) => {
  return isUrl(url)
}

const CreatePostBar: React.FC<IProps> = (props) => {

  const classes = useStyles()

  const [postUrl, setPostUrl] = useState('')
  const [isValid, setIsValid] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value: url } = e.target
    setPostUrl(url)
    setIsValid(validatePostUrl(url))
  }

  const handleSubmit = (e: React.FormEvent) => {
    props.onSubmit(postUrl)
    e.preventDefault()
  }

  return (
    <Paper className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Toolbar>
          <Box className={classes.icon}>
            <InsertLinkIcon />
          </Box>
          <Box className={classes.inputContainer} flex={1}>
            <TextField
              onChange={handleChange}
              placeholder="https://yourlink.here"
              fullWidth
              value={postUrl}
            />
          </Box>
          <Button
            disabled={!isValid}
            type="submit"
          >
            Submit
          </Button>
        </Toolbar>
      </form>
    </Paper>
  )
}

export default CreatePostBar