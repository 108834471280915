import React from 'react'

import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  logo: {
    color: '#FFF',
    fontSize: 24,
    textDecoration: 'none',
    '&:visited': {
      color: '#FFF',
    }
  }
}))

const Logo: React.FC = () => {
  const classes = useStyles()
  return (
    <Link className={classes.logo} to="/">Rumble</Link>
  )
}

export default Logo