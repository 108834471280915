import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

interface IProps {
  dismiss: () => void,
  onSignIn: (username: string, password: string) => void,
}

const useStyles = makeStyles(theme => ({
  inputContainer: {
    margin: theme.spacing(2),
  }
}))

const SignInModal: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const [username, setUsername] = useState('')
  const [usernameError, setUsernameError] = useState('')

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const handleSubmit = (e: React.FormEvent) => {
    signIn()
    e.preventDefault()
  }

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setUsername(value)
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setPassword(value)
  }

  const signIn = async () => {
    props.onSignIn(username, password)
  }

  const handleError = (error: any) => {
    let hasUsernameError, hasPasswordError

    // TODO

    if (!hasUsernameError) {
      setUsernameError('')
    }

    if (!hasPasswordError) {
      setPasswordError('')
    }
  }

  return (
    <Dialog open={true} onClose={props.dismiss} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <DialogTitle>Sign In</DialogTitle>
        <DialogContent>
          <Box className={classes.inputContainer}>
            <TextField
              error={Boolean(usernameError)}
              helperText={usernameError}
              label="email"
              onChange={handleUsernameChange}
              variant="filled"
              fullWidth
            />
          </Box>
          <Box className={classes.inputContainer}>
            <TextField
              error={Boolean(passwordError)}
              helperText={passwordError}
              label="password"
              onChange={handlePasswordChange}
              type="password"
              variant="filled"
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.dismiss} type="button">Cancel</Button>
          <Button variant="contained" color="primary" type="submit">Sign In</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default SignInModal