import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { createUser } from '../../api/rumble'

import { signIn } from 'features/authentication/authSlice'

import RegisterModal from './RegisterModal'

interface IProps {
  dismiss: () => void,
}

const RegisterContainer: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()

  const handleRegister = async (
    username: string,
    email: string,
    password: string,
  ) => {
    try {
      const res = await createUser(username, email, password)
      dispatch(signIn(username, password))
      props.dismiss()
    } catch (err) {
      alert(err)
    }
  }

  return (
    <RegisterModal
      dismiss={props.dismiss}
      onRegister={handleRegister}
    />
  )
}

export default RegisterContainer