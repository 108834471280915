import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import amber from '@material-ui/core/colors/amber'
import yellow from '@material-ui/core/colors/yellow'

import AddIcon from '@material-ui/icons/Add'
import StarIcon from '@material-ui/icons/Star'

interface IProps {
  balance: number,

  onAddPress: () => void,
}

const useStyles = makeStyles(theme => ({
  addButton: {
    marginLeft: theme.spacing(1),
  },
  balance: {
  },
  icon: {
    alignItems: 'center',
    backgroundColor: amber['500'],
    borderRadius: 36,
    display: 'flex',
    height: 36,
    justifyContent: 'center',
    width: 36,
    color: yellow['A200'],
  },
  value: {
    flex: 1,
    fontSize: 18,
    textAlign: 'right',
  }
}))

const CreditBalance: React.FC<IProps> = (props) => {

  const classes = useStyles()

  return (
    <Toolbar>
      <Box className={classes.icon}>
        <StarIcon color="inherit" />
      </Box>

      <Typography className={classes.value}>
        {Math.round(props.balance).toLocaleString()}
      </Typography>

      <IconButton
        className={classes.addButton}
        onClick={props.onAddPress}
        size="medium"
      >
        <AddIcon />
      </IconButton>
    </Toolbar>
  )
}

export default CreditBalance