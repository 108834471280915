import { configureStore, Action } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'

import rootReducer, { RootState } from './rootReducer'

import { loadState, saveState } from './localStorage'

import throttle from 'lodash/throttle'

import { initialState } from 'features/authentication/authSlice'

const localState = loadState()

const store = configureStore({
  reducer: rootReducer,
  preloadedState: {
    auth: {
      ...initialState,
      ...localState.auth,
    },
  },
})

store.subscribe(throttle(() => {
  saveState({
    auth: store.getState().auth,
  })
}, 1000))

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store
