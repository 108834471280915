import React from 'react'

import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import History from 'features/history'

const useStyles = makeStyles(theme => ({
  profilePage: {
    marginTop: theme.spacing(4),
  },
}))

const ProfilePage: React.FC = (props) => {
  const classes = useStyles()
  return (
    <Container className={classes.profilePage}>
      <Box>
        <History />
      </Box>
    </Container>
  )
}

export default ProfilePage